.allowanceResultUnitParent {
    width: 90%;
    max-width: 900px;
    background-color: #424242;
    margin-bottom: 1em;
}

.allowanceResultUnit {
    padding-top: 1em;
    padding-bottom: 1em;
    padding-left: 0.5em;
    padding-right: 0.5em;
}

.allowanceResultUnitTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.allowanceResultUnitTitleText {
    text-align: left;
    font-size: 1.2em;
    padding-left: 0.55em;
    margin-block-start: 0;
    margin-block-end: 0.25em;
    flex-grow: 1;
}

.allowanceResultUnitBody {
    display: flex;
    align-items: center;
    padding-top: 8px;
    padding-left: 6px;
}

.allowanceResultUnitBodyTitle {
    text-align: left;
    font-size: 0.85em;
    padding-left: 0.5em;
    white-space: nowrap;
}

.allowanceResultUnitBodyHours {
    text-align: left;
    font-size: 1.2em;
    padding-left: 0.25em;
}

.allowanceResultUnitBar {
    width: 95%;
    transform: translateX(2.5%);
    margin-top: 0.9em;
}

.allowanceResultUnitBarText {
    text-align: right;
    font-size: 0.8em;
    color: lightgray;
}

.allowanceResultUnitExpandText {
    font-size: 1em !important;
}

.allowanceResultUnitExpandIcon {
    color: white;
}
.allowanceResultUnitTextResultLogo {
    padding-left: 0.5em;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
}

.allowanceResultUnitTextResultLogoSVG {
    height: 1em !important;
    width: 1em !important;
}

.allowanceResultUnitTextResultText {
    padding-left: 0.5em;
    text-align: left;
}
/* Custom CSS styling for react-big-calendar month view */

/* Override the background color of the month view */
.rbc-month-view {
    background-color: #424242;
}

/* Override the background color of today in month view */
.rbc-today {
    background-color: #3f51b5;
}

/* Override the font-size for event title */
.rbc-event-content {
    font-size: 0.8em;
    max-width: 10vw;
}
.navBar {
    height: 5%;
    min-height: 32px;
    width: 100vw;
    background-color: #282c34;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    position: absolute;
    left: 0;
}

.navBarMode {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: min(1rem, 3vmin);
    color: white;
    margin-top: 8px;
    margin-left: 8px;
}

.navBarSelectText {
    color: white !important;
    text-align: center;
}

.navBarButton {
    color: white !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-left: 3vmin !important;
    padding-right: 3vmin !important;
    margin-top: 1vh !important;
}

.navBarButtonDisabled {
    color: #282c34 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-left: 3vmin !important;
    padding-right: 3vmin !important;
    margin-top: 1vh !important;
}

.navBarButtonIcon {
    font-size: 1em !important;
}
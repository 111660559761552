.dateSelect {
	height: 100svh;
	overflow-x: hidden;
	text-align: center;
}

.dateSelectLogo {
	height: 35vmin;
	max-height: 190px;
}

.dateSelectHeader {
	background-color: #282c34;
	min-height: 95svh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: 1.2em;
	color: white;
}

.dateSelectBody {
	max-width: 100%;
}

.datePicker {
	transform: scale(1.2);
    margin-bottom: 32px;
}

.dateSelectBodyText {
	width: 100%;
	font-size: 1.2em;
	margin-block-start: 4vh;
    margin-block-end: 4vh;
}

.dateSelectBodyButtonDiv {
	height: 10svh;
	margin-top: 4vh;
	display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
	column-gap: 20px;
}

.dateSelectBodyButton {
	width: 40%;
    height: 75%;
    font-size: 0.8em !important;
    padding: 10px !important;
}

.dateSelectFooter {
	height: 5%;
	min-height: 32px;
	background-color: #282c34;
	margin: 0;
	display: flex;
    justify-content: center;
}
.home {
	height: 95%;
	text-align: center;
}

.homeLogo {
	height: 35vmin;
	max-height: 230px;
}

.homeHeader {
	background-color: #282c34;
	min-height: 90%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: 1.2em;
	color: white;
}

.homeBody {
	max-width: 100%;
}

.homeBodyTitle {
	width: 100%;
	font-size: 1.2em;
}

.homeBodyButton {
	width: 100%;
    font-size: 0.8em !important;
    padding: 10px !important;
}

.homeFooter {
	height: 5%;
	min-height: 32px;
	background-color: #282c34;
	margin: 0;
	display: flex;
    justify-content: center;
}
.inputDivWrapper {
    width: 100%;
}

.setForWholeWeekCheckbox {
    margin-top: -12px !important;
}

@media (min-width: 960px) {
    .setForWholeWeekCheckbox {
        margin-top: 30px !important;
        margin-bottom: 8px !important;
    }
}
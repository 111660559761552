.allowanceResult {
	text-align: center;
	height: 90svh;
	top: 5vh;
    position: relative;
}

.allowanceResultHeader {
	padding-top: 1em;
	background-color: #282c34;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	font-size: 1em;
	color: white;
	overflow-y: auto;
}

.allowanceResultTitle {
	width: 100%;
	font-size: 1.1em;
	margin-block-end: 0;
}

.allowanceResultEndSpacer {
	min-height: 2em;
}